import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const Notes = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState({ store: "", description: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [filterHasDescription, setFilterHasDescription] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/notes`);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
      const result = await response.json();
      setData(result);
      setFilteredData(result); // Initialize filtered data
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Gagal mendapatkan data dari server.");
    }
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error,
      });
    }
  }, [error]);

  useEffect(() => {
    fetchData();
    Swal.fire({
      title: "Catatan Notifikasi",
      text: "Tambahkan catatan yang akan muncul di notifikasi whatsapp.",
      // imageUrl: "https://unsplash.it/400/200",
      // imageWidth: 400,
      // imageHeight: 200,
      // imageAlt: "Custom image"
    });
  }, []);

  useEffect(() => {
    const filtered = data.filter((item) => {
      const matchesStore = item.nama_store.toLowerCase().includes(search.store.toLowerCase());
      const matchesDescription = item.deskripsi
        ? item.deskripsi.toLowerCase().includes(search.description.toLowerCase())
        : search.description === ""; // Cocokkan jika deskripsi tidak ada dan input kosong
      const hasDescription = filterHasDescription ? !!item.deskripsi : true;
      return matchesStore && matchesDescription && hasDescription;
    });
    setFilteredData(filtered);
  }, [search, data, filterHasDescription]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFilterHasDescription(e.target.checked);
  };

  const handleEditClick = (item) => {
    setEditData(item);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditData(null);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/notes/${editData.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editData),
      });
      if (!response.ok) throw new Error("Failed to save changes.");
      Swal.fire("Success", "Catatan berhasil diupdate.", "success");
      fetchData(); // Refresh data
      handleModalClose();
    } catch (error) {
      console.error("Error updating note:", error);
      Swal.fire("Error", "Gagal menyimpan perubahan.", "error");
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col max-w-4xl mx-auto gap-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <input
              type="text"
              name="store"
              value={search.store}
              onChange={handleSearchChange}
              placeholder="Cari by Store"
              className="p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="description"
              value={search.description}
              onChange={handleSearchChange}
              placeholder="Cari by Catatan"
              className="p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-4">
          <input
            type="checkbox"
            id="filter-has-description"
            checked={filterHasDescription}
            onChange={handleCheckboxChange}
            className="w-4 h-4"
          />
          <label htmlFor="filter-has-description" className="text-sm text-gray-700">
            Tampilkan hanya yang memiliki catatan
          </label>
        </div>
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row p-4 bg-white shadow rounded-md border-gray-300 gap-4 md:gap-0"
            >
              <div className="md:flex-1 text-blue-500 font-bold">{item.nama_store}</div>
              <div className="md:flex-1">{"AC" + item.nama_device.slice(-2)}</div>
              <div className="md:flex-1 text-sm">
                {item.deskripsi && item.deskripsi.length > 40
                  ? item.deskripsi.slice(0, 40) + "..."
                  : item.deskripsi}
              </div>
              <div className="md:flex-1 flex justify-end">
                <button
                  className="text-blue-500 font-bold py-2 px-4 rounded"
                  onClick={() => handleEditClick(item)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Tidak ada data yang ditemukan.</p>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">Edit Catatan</h2>
            <div>
              <label className="block text-gray-700 font-bold mb-2">{editData.nama_store}</label>
            </div>
            <div>
              <label className="block text-gray-700 font-bold mb-2">{'AC' + editData.nama_device.slice(-2)}</label>
            </div>
            <div>
              <textarea
                value={editData.deskripsi}
                onChange={(e) => setEditData({ ...editData, deskripsi: e.target.value })}
                className="p-2 w-full border border-gray-300 rounded-md"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalClose}
                className="bg-gray-500 text-white py-2 px-4 rounded"
              >
                Batal
              </button>
              <button
                onClick={handleSaveChanges}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notes;
