import React from "react";
import Card from "components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faGear, faKey, faLock, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const User = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  const navigate = useNavigate();

  const handleLogout = () => {
    // buat konfirmasi dengan sweetalert
    Swal.fire({
      text: "Apakah anda yakin?",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Logout!',
          'You have been logout.',
          'success'
        )
        localStorage.removeItem("userData");
        navigate("/auth/login");
      }
    })
    
  }

  function ucwords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const menuClick = () => {
    Swal.fire({
      title: '',
      text: `Menu ini sedang dalam maintenance`,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    });
  }

  const Menu = ({label, onClick}) => {
    return (
      <div>
        <button onClick={onClick} style={{cursor: 'pointer'}} className="text-sm">{label}</button>
      </div>
    );
  };

  return (
    <div className="m-5 flex flex-col gap-5">
      <div className="flex flex-row items-center">
          <div className="w-7"><FontAwesomeIcon icon={faGear} /></div>
          <div className="text-xl">Setting</div>
      </div>
        {userData.role_id === 1 && <Menu onClick={() => navigate('/broadcast')} label={'Broadcast'} />}
        {userData.role_id === 1 && <Menu onClick={() => navigate('/account')} label={'Data Akun'} />}
        {userData.role_id === 1 && <Menu onClick={() => navigate('/store')} label={'Data Store'} />}
        {userData.role_id === 1 && <Menu onClick={() => navigate('/backup')} label={'Database Backup'} />}
        {(userData.role_id === 1 || userData.role_id === 2) && <Menu onClick={() => navigate('/notes')} label={'Catatan Notifikasi'} />}
        {userData.role_id === 1 && <Menu onClick={() => navigate('/logs')} label={'Log Sistem'} />}
        <Menu onClick={menuClick} label={'Ganti Password'} />
        <div><button onClick={handleLogout} className="text-sm text-white bg-brand-500 rounded-lg px-4 py-2 mt-5 w-full md:w-auto"><FontAwesomeIcon icon={faSignOut} /> Logout</button></div>
    </div>
  );
};

export default User;
