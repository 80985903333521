import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";
import { RenderActive } from "helpers/statusHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';

const Broadcast = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataBroadcast, setDataBroadcast] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    id : "",
    id_store: "",
    list_ac: [],
    jam_mulai: "",
    jam_selesai: "",
    code: "T",
    value: "",
  });

  const options = [
    { value: '1', label: 'AC01' },
    { value: '2', label: 'AC02' },
    { value: '3', label: 'AC03' },
    { value: '4', label: 'AC04' },
  ];

  // Fetch broadcast and store data
  const fetchBroadcastData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      const filteredData = data.data_broadcast.filter((item) => item.code === "T");
      setDataBroadcast(filteredData);
      setDataStore(data.data_store);
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };
// Add new broadcast data
const handleSubmit = async () => {
  try {
    // Validasi data form
    const { id, id_store, jam_mulai, jam_selesai, code, value } = formData;
    if (!id_store || !jam_mulai || !jam_selesai || !code || !value) {
      throw new Error("Data tidak lengkap. Pastikan semua field terisi.");
    }

    // Tentukan URL dan metode
    const url = id 
      ? `${API_DOMAIN}/broadcast/${id}` 
      : `${API_DOMAIN}/broadcast`;
    const method = id ? "PUT" : "POST";

    // Opsi fetch
    const fetchOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    // Fetch data
    const response = await fetchWithJwt(url, fetchOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
    }

    // Jika sukses
    Swal.fire({
      icon: "success",
      title: id ? "Broadcast berhasil diperbarui" : "Broadcast berhasil ditambahkan",
      showConfirmButton: false,
      timer: 1500,
    });

    // Refresh data
    fetchBroadcastData();

    // Reset form
    setFormData({
      id: "",
      id_store: "",
      list_ac: [],
      jam_mulai: "",
      jam_selesai: "",
      code: "T",
      value: "",
    });

    setIsModalOpen(false);
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Broadcast gagal",
      text: error.message,
      showConfirmButton: false,
      timer: 1500,
    });
    console.error("Error in handleSubmit:", error);
  }
};


  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeAc = (selectedOptions) => {
    console.log(selectedOptions);
    setFormData({
      ...formData,
      list_ac: selectedOptions,
    });
  };

// Handle delete broadcast
const handleDelete = async (id) => {
  try {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Broadcast akan dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal'
    });

    // Check if the user confirmed the deletion
    if (!result.isConfirmed) {
      return;
    }

    // Perform the DELETE request
    const response = await fetchWithJwt(`${API_DOMAIN}/broadcast/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Show success message
    await Swal.fire({
      icon: "success",
      title: "Broadcast berhasil dihapus",
      showConfirmButton: false,
      timer: 1500,
    });

    // Refresh broadcast data
    fetchBroadcastData();
  } catch (error) {
    console.error("Error deleting broadcast:", error);

    // Show error message
    Swal.fire({
      icon: "error",
      title: "Broadcast gagal dihapus",
      text: error.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

// Handle edit broadcast
const handleEdit = (broadcast) => {
  setFormData({
    id : broadcast.id,
    id_store: broadcast.id_store,
    list_ac: broadcast.list_ac,
    jam_mulai: broadcast.jam_mulai,
    jam_selesai: broadcast.jam_selesai,
    code: broadcast.code,
    value: broadcast.value,
  });
  setIsModalOpen(true);
};

const handleAdd = () => {
  setFormData({
    id : "",
    id_store: "",
    list_ac: [],
    jam_mulai: "",
    jam_selesai: "",
    code: "T",
    value: "",
  });
  setIsModalOpen(true);
};


  // Handle search filter
  const searchHandle = (value) => {
    setSearchValue(value);
    const filtered = dataBroadcast.filter((item) =>
      item.nama_store.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchBroadcastData();
  }, []);

  const formatTime = (time) => {
    return time ? time.substring(0, 5) : "";
  };

  return (
    <div className="p-4 bg-white rounded mx-4">
      {/* Button to open modal */}
      <button
        onClick={() => handleAdd()}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Tambah Broadcast
      </button>

      {/* Search input */}
      <input
        value={searchValue}
        onChange={(e) => searchHandle(e.target.value)}
        type="text"
        placeholder="Cari..."
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Display broadcast list */}
      {filteredData.length > 0 ? (
      <>
        {/* Header */}
        <div className="p-4 hidden md:flex bg-white flex-col md:flex-row justify-between border border-gray-200 mb-2 rounded">
          <div className="w-full md:w-1/5 font-bold">Store</div>
          <div className="w-full md:w-1/5 font-bold">Jam</div>
          <div className="w-full md:w-1/5 font-bold">Status</div>
          <div className="w-full md:w-1/5 font-bold">Temperatur</div>
          <div className="w-full md:w-1/5 font-bold">Aksi</div>
        </div>

        {/* Data Rows */}
        {filteredData.map((item, index) => (
          <div
            key={index}
            className="p-4 bg-white flex flex-col md:flex-row justify-between border border-gray-200 mb-2 rounded hover:bg-gray-100"
          >
            <div className="w-full md:w-1/5 text-cyan-600 font-bold">{item.nama_store}</div>
            <div className="w-full md:w-1/5">
              {formatTime(item.jam_mulai)} - {formatTime(item.jam_selesai)}
            </div>
            <div className="w-full md:w-1/5">{RenderActive(item.status)}</div>
            <div className="w-full md:w-1/5 font-bold">{item.value}°C</div>
            <div className="w-full md:w-1/5 flex justify-end md:justify-start gap-5">
                <button>
                  <FontAwesomeIcon className="text-blue-500" onClick={() => handleEdit(item)} icon={faEdit} />
                </button>
                <button>
                  <FontAwesomeIcon className="text-red-500" onClick={() => handleDelete(item.id)} icon={faTrash} />
                </button>
            </div>
          </div>
        ))}
      </>
      ) : (
        // Empty State
        <div className="text-center text-gray-500">Tidak ada data</div>
      )}


      {/* Modal Add Broadcast */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Tambah Broadcast</h2>
            <div className="flex flex-col gap-4">
              <label htmlFor="id_store">Store</label>
              <select
                name="id_store"
                value={formData.id_store}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              >
                <option value="">Pilih Store</option>
                {dataStore.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nama}
                  </option>
                ))}
              </select>

              <label htmlFor="list_ac">AC</label>
              <Select
                name="list_ac"
                value={formData.list_ac}
                onChange={handleChangeAc}
                options={options}
                isMulti
                className="w-full p-2 mb-2 border rounded"
              />

              <label htmlFor="jam_mulai">Jam Mulai</label>
              <input
                type="time"
                name="jam_mulai"
                value={formData.jam_mulai}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />

              <label htmlFor="jam_selesai">Jam Selesai</label>
              <input
                type="time"
                name="jam_selesai"
                value={formData.jam_selesai}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />


              <label htmlFor="value">Value</label>
              <input
                type="text"
                name="value"
                value={formData.value}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />

              <button
                onClick={handleSubmit}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Simpan
              </button>

              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Broadcast;
