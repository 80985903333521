import React, { useState } from "react";
import Detail from "views/deviceShow/detail";
import DataSensor from "./dataSensor";
import logo from 'assets/img/logo.jpg';

const Store = () => {
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div className="flex flex-grow h-screen overflow-hidden md:px-20">
      {/* Bagian tengah untuk DataSensor */}
      <div
        className={`${
          selectedId ? "hidden md:block" : "block"
        } w-full md:w-1/3 bg-white md:border-r`}
      >
        <DataSensor selectedId={selectedId} setSelectedId={setSelectedId} />
      </div>

      {/* Bagian kanan untuk Detail */}
      <div
        className={`${
          selectedId ? "block" : "hidden md:block"
        } w-full md:w-2/3 overflow-y-scroll scrollbar-none mb-20`}
      >
        {selectedId ? (
          <Detail
            key={selectedId}
            selectedDeviceId={selectedId}
            onClose={() => setSelectedId(null)}
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <div><img src={logo} alt="Logo" className="w-20 h-20 opacity-50" /></div>
            <div>Pilih AC untuk melihat detailnya</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Store;
