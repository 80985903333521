import React, { useState, useEffect } from "react";
const {fetchWithJwt} = require("components/fetchWithJwt");

const Backup = () => {
  const apiUrl = process.env.REACT_APP_API_DOMAIN;
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchWithJwt(apiUrl + "/backup")
        .then((response) => response.json()) // Parse JSON
        .then((json) => {
            setData(json.backups); // Set data ke state
        })
        .catch((error) => console.error("Error fetching data:", error)); // Tangani error
  }, []);

  const handleDownload = (filename) => {
    fetchWithJwt(`${apiUrl}/backup/download/${filename}`, {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Failed to download file: ${response.statusText}`);
            }
            return response.blob();
        })
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading file:", error));
  };


  return (
    <div className="p-4">
      <div className="p-4 bg-white rounded-xl shadow-md">
        <table className="bg-white border-collapse w-full">
          <thead>
            <tr>
              <th className="text-left">
                No.
              </th>
              <th className="text-left">
                Nama
              </th>
              <th className="text-left">
                File
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr className="hover:bg-gray-100 border-b h-12" key={index}>
                <td>{index + 1}</td>
                <td>{item}</td>
                <td><button onClick={() => handleDownload(item)} className="px-4 py-1 text-blue-500 rounded-md">Download</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Backup;
