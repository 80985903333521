import Card from 'components/card';
import html2canvas from 'html2canvas';
import { fetchWithJwt } from 'components/fetchWithJwt';
import React, { useState, useEffect, useCallback } from 'react';
import { AreaChart, Area, Tooltip, CartesianGrid, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { MdWaterDrop, MdThermostat, MdBatteryFull, MdSettings, MdLocationPin, MdBarChart, MdArrowCircleLeft, MdArrowCircleRight, MdArrowLeft, MdArrowBack } from 'react-icons/md';
import Remote from '../deviceControl/deviceControlRemote';
import BroadcastSection from './broadcast';

const Detail = ({ selectedDeviceId, onClose }) => {
  const [error, setError] = useState(null);
  const [device, setDevice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('hourly');
  const [sensorData, setSensorData] = useState([]);
  const [notifStatus, setNotifStatus] = useState(null);
  const [broadcastStatus, setBroadcastStatus] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const userRoleId = parsedUserData.role_id;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const isToday = currentDate === new Date().toISOString().split('T')[0];

  const handleNextDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() + 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() + 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };
  
  const handlePreviousDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() - 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() - 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };

  const downloadData = () => {
    const chartContainer = document.querySelector('.chart-container');
    if (chartContainer) {
      html2canvas(chartContainer).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'sensor_data_chart.png';
        link.click();
      }).catch(error => {
        console.error('Error generating image:', error);
      });
    } else {
      console.error('Chart container not found.');
    }
  };

  const processData = useCallback((data) => {
    return data.map(sensor => {
      const formattedTime = period === 'daily' ? new Date(sensor.tanggal).toLocaleDateString('id-ID', {day: '2-digit', month: '2-digit'}) : sensor.jam;
      return {
        time: formattedTime,
        temperature: (sensor.temperature / 10).toFixed(1),
        humidity: (sensor.humidity).toFixed(1),
        name: sensor.name
      };
    });
  }, [period]);

  const fetchDeviceDetail = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/detail/${selectedDeviceId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const { data_alat } = await response.json();
      setDevice(data_alat);
      setNotifStatus(data_alat.notif);
      setBroadcastStatus(data_alat.broadcast);
    } catch (error) {
      setError('Failed to fetch device details');
    }
  };

  const fetchData = async (date, period) => {
    try {
      const [year, month] = date.split('-');
      const url = period === 'daily' ? `${API_DOMAIN}/monitor/data_daily` : `${API_DOMAIN}/monitor/data_hourly`;
      const response = await fetchWithJwt(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({id: selectedDeviceId, date, month: month.padStart(2, '0'), year}),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setSensorData(processData(data));
    } catch (error) {
      setError(`Failed to fetch data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchAllData = () => {
      fetchDeviceDetail();
      fetchData(currentDate, period);
    };
  
    fetchAllData(); // Fetch langsung saat komponen mount
  
    const interval = setInterval(fetchAllData, 60000); // Fetch setiap 6 detik
  
    return () => clearInterval(interval); // Bersihkan interval saat unmount
  }, [currentDate, period]); // Tambahkan dependency array
  

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full text-gray-500">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <Card extra="mt-5 items-center">
        <div className="text-xl font-bold text-navy-700">{error}</div>
      </Card>
    );
  }

  return (
    <div className='bg-white h-full overflow-hidden'>
      <div className="p-4 shadow-md flex items-center gap-2"><MdArrowBack className="cursor-pointer text-2xl text-blue-600" onClick={onClose} />Kembali</div>
      <Card className="p-4 h-full overflow-y-scroll scrollbar-none pb-[200px]">
        <div className="flex flex-col gap-2 md:text-left">
          <div className="font-bold">{device.name ? 'AC '+device.name.slice(5,7) : ''}</div>
          <div className="">{device.store}</div>
          <div className="">
            {device.status && (
              <div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col w-full items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 p-5 rounded-md">
                  <div>Suhu</div>
                  <div className='flex flex-row items-center'>
                    <div className='text-xl'><MdThermostat className="" /></div>
                    <div className='text-xl font-bold'>{`${device.status && device.status.find(stat => stat.code === 'va_temperature')?.value/10}°C`}</div>
                  </div>
                </div>
                <div className="flex flex-col w-full items-center justify-center text-white bg-gradient-to-r from-cyan-500 to-blue-500 p-5 rounded-md">
                  <div>Kelembaban</div>
                  <div className='flex flex-row items-center'>
                    <div className='text-xl'><MdWaterDrop className="" /></div>
                    <div className='text-xl font-bold'>{`${device.status && device.status.find(stat => stat.code === 'va_humidity')?.value}%`}</div>
                  </div>
                </div>
              </div>
              </div>
            )}
          </div>

          <div className="border rounded-xl flex flex-col overflow-hidden">
            <div className='flex flex-row p-2 justify-between'>
              <div className='font-bold'>Sensor Temperatur</div>
              <div className=''>{renderStatusIcon(device.online)}</div>
            </div>
            {/* <div className='flex justify-center text-sm text-white bg-blue-500 p-2'>Detail</div> */}
          </div>
          
          <div className="border rounded-xl flex flex-col overflow-hidden">
            <div className='flex flex-row p-2 justify-between'>
              <div className='font-bold'>IR Remote</div>
              <div className=''>{renderStatusIcon(device.remote_online)}</div>
            </div>
            {/* <div className='flex justify-center text-sm text-white bg-blue-500 p-2'>Detail</div> */}
          </div>
          
          <div className="border rounded-md p-2">
            <div className="font-bold">Catatan</div>
            <div>{device.deskripsi ? device.deskripsi : '-'}</div>
          </div>
        
          <PeriodTabs period={period} setPeriod={setPeriod} />
          <div className="flex items-center justify-between">
            <button onClick={handlePreviousDay} className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center">
              <MdArrowCircleLeft className="mr-2" /> Previous
            </button>
            <span className="text-xl font-bold text-gray-700">
              {period === 'hourly' ? new Date(currentDate).toLocaleDateString('id-ID', {day: '2-digit', month: '2-digit', year: 'numeric'}) : new Date(currentDate).toLocaleDateString('id-ID', {month: 'long', year: 'numeric'})}
            </span>
            <button disabled={isToday} onClick={handleNextDay} className={`px-4 py-2 rounded-md ${isToday ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} text-white flex items-center`}>
              <MdArrowCircleRight className="mr-2" /> Next
            </button>
          </div>
          <ChartSection chartData={sensorData} downloadData={downloadData} />
          {userRoleId === 1 && device.remote && <RemoteSection remote={device.remote} />}
          {/* {userRoleId === 1 && <Setting notifStatus={notifStatus} setNotifStatus={setNotifStatus} deviceId={selectedDeviceId} />} */}
          {userRoleId === 1 && <BroadcastSection broadcastStatus={broadcastStatus} setBroadcastStatus={setBroadcastStatus} deviceId={device.remote} />}
        </div>
      </Card>
    </div>
  );
};

const PeriodTabs = ({ period, setPeriod }) => (
  <div className="flex items-center justify-center mt-5">
    <PeriodTabButton period={period} setPeriod={setPeriod} target="hourly" label="Day" />
    <PeriodTabButton period={period} setPeriod={setPeriod} target="daily" label="Month" />
  </div>
);

const PeriodTabButton = ({ period, setPeriod, target, label }) => (
  <button onClick={() => setPeriod(target)} className={`text-center w-full py-2 ${period === target ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>{label}</button>
);

const ChartSection = ({ chartData , downloadData}) => (
  <div className="">
    <ChartContainer color="#8884d8" domain={[20, 30]} title="Temperature" dataKey="temperature" chartData={chartData} />
    <ChartContainer color="#03b575" domain={[50, 70]} title="Humidity" dataKey="humidity" chartData={chartData} />
    <div className="flex justify-center mt-5">
      <button onClick={downloadData} className="px-4 py-2 bg-green-500 hover:bg-opacity-80 text-white rounded-md flex items-center">Download Chart</button>
    </div>
  </div>
);

const RemoteSection = ({ remote }) => (
  <div className="shadow-md p-5">
    <Remote deviceId={remote} />
  </div>
);

const ChartContainer = ({ color, domain, title, dataKey, chartData }) => (
  <div className=''>
    <ResponsiveContainer height={200}>
      <AreaChart data={chartData}>
        <Tooltip />
        {/* <YAxis domain={domain}/> */}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time">
          <Label value={title} offset={10} position="top" />
        </XAxis>
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke={color}
          fill={color}
          // dot={{ stroke: color, strokeWidth: 1, fill: '#fff', r: 2 }}
          // label={{ position: 'top', fill: '#000', fontSize: 12 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);


const renderStatusIcon = (isOnline) => {
  return isOnline 
    ? <span className="text-green-500">Online</span> 
    : <span className="text-red-500">Offline</span>;
};

export default Detail;
