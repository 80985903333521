// helpers.js

export const renderColor = (value) => {
    if (value / 10 > 26) return 'bg-gradient-to-r from-red-500 via-red-300 to-red-500';
    if (value / 10 < 23) return 'bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500';
    return 'bg-gradient-to-r from-green-500 via-green-300 to-green-500';
  };
  
  export const renderWindSpeed = (value) => {
    if (value == '0') return 'Auto';
    if (value == '1') return 'Low';
    if (value == '2') return 'Medium';
    if (value == '3') return 'High';
    return '';
  };
  
  export const renderStatusOnline = (value) => {
    if (value == '0') return 'text-gray-500';
    return '';
  };
  
  export const renderMode = (value) => {
    if (value == '0') return 'Cold';
    if (value == '1') return 'Hot';
    if (value == '2') return 'Auto';
    if (value == '3') return 'Speed';
    return '';
  };

  export const RenderActive = (value) => {
    if (value == '0') return 'Tidak Aktif';
    if (value == '1') return 'Aktif';
    return '';
  };
  