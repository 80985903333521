import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataStore, setDataStore] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null); // Untuk menyimpan store yang dipilih
  const [formData, setFormData] = useState({
    nama: "",
    alamat: "",
    jamBuka: "",
    jamTutup: "",
    is24Hours: true,
  });

  // Ambil data store dari API
  const fetchStoreData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/store`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setDataStore(data);
        setFilteredData(data);
      } else {
        throw new Error("Data tidak valid");
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  // Tambahkan data store baru
  const addStore = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      setIsModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Store berhasil ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });
      fetchStoreData();
      setFormData({
        nama: "",
        alamat: "",
        jamBuka: "",
        jamTutup: "",
        is24Hours: true,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Store gagal ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error adding store:", error);
    }
  };

  // Handle perubahan pada form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "is24Hours" ? value === "1" : value,
    }));
  };

  // Fungsi untuk menangani klik pada store
  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  // Fungsi debounced search
  const searchHandle = (value) => {
    setSearchValue(value);
    const filtered = dataStore.filter((item) =>
      item.nama.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const formatTime = (time) => {
    return time.substring(0, 5);
  };

  useEffect(() => {
    fetchStoreData();
  }, []);

  return (
    <div className="p-4">
      {/* Button Tambah Store */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Tambah Store
      </button>

      {/* Input Pencarian */}
      <input
        value={searchValue}
        onChange={(e) => searchHandle(e.target.value)}
        type="text"
        placeholder="Cari..."
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Daftar Store */}
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <div
            onClick={() => handleStoreClick(item)}
            key={index}
            className="p-4 border border-gray-200 mb-2 rounded cursor-pointer hover:bg-gray-100"
          >
            <p>{item.nama}</p>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">Tidak ada data</p>
      )}
  
      {/* Modal Tambah Store */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Tambah Store</h2>
            <div className="flex flex-col gap-4">
              <input
                type="text"
                name="nama"
                value={formData.nama}
                onChange={handleChange}
                placeholder="Nama Store"
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                name="alamat"
                value={formData.alamat}
                onChange={handleChange}
                placeholder="Alamat"
                className="w-full p-2 mb-2 border rounded"
              />
              <select
                name="is24Hours"
                value={formData.is24Hours ? "1" : "0"}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              >
                <option value="1">24 jam</option>
                <option value="0">Tidak</option>
              </select>
              {!formData.is24Hours && (
                <>
                  <input
                    type="time"
                    name="jamBuka"
                    value={formData.jamBuka}
                    onChange={handleChange}
                    className="w-full p-2 mb-2 border rounded"
                  />
                  <input
                    type="time"
                    name="jamTutup"
                    value={formData.jamTutup}
                    onChange={handleChange}
                    className="w-full p-2 mb-2 border rounded"
                  />
                </>
              )}
              <button
                onClick={addStore}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Simpan
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal Detail Store */}
      {selectedStore && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Detail Store</h2>
            <div className="flex flex-col gap-4">
            <div className="flex flex-row">
                <div className="w-1/3">ID Store</div>
                <div className="w-2/3 font-bold">{selectedStore.id}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Nama</div>
                <div className="w-2/3 font-bold">{selectedStore.nama}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Alamat</div>
                <div className="w-2/3 font-bold">{selectedStore.alamat}</div>
              </div>
              {selectedStore.is24Hours ? (
                <div className="flex flex-row">
                  <div className="w-1/3">Jam Operasional</div>
                  <div className="w-2/3 font-bold">24 Jam</div>
                </div>
              ) : (
                <div className="flex flex-row">
                  <div className="w-1/3">Jam Operasional</div>
                  <div className="w-2/3 font-bold">{formatTime(selectedStore.jam_buka)} - {formatTime(selectedStore.jam_tutup)}</div>
                </div>
              )}
              <button
                onClick={() => setSelectedStore(null)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
