import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";

const LogViewer = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN; // Sesuaikan dengan domain API Anda
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Fetch log data dari API
  const fetchLogs = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/log`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setLogs(data);
      setFilteredLogs(data);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  // Handle search
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filtered = logs.filter((log) =>
      Object.values(log).some((val) =>
        String(val).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredLogs(filtered);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };


  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <div className="p-4">
      {/* Search input */}
      <input
        type="text"
        placeholder="Cari log..."
        value={searchValue}
        onChange={handleSearch}
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Log table */}
      <div className="text-xs p-2 h-[70vh] overflow-y-scroll bg-black text-green-500">
        {filteredLogs.map((log, index) => (
          <div key={index} className="flex flex-row gap-2">
            <div className="w-1/4">{formatTimestamp(log.timestamp)}</div>
            <div className="w-3/4">{log.message}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogViewer;
