import PropTypes from 'prop-types';
import Toggle from 'components/toggle';
import { useState, useEffect } from 'react';
import { fetchWithJwt } from 'components/fetchWithJwt';

const Setting = ({ broadcastStatus, setBroadcastStatus, deviceId }) => {

  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const handleChange = async () => {
    const confirmAction = window.confirm('Do you want to change the notification status?');
    if (!confirmAction) {
      return;
    }
    
    const newStatus = broadcastStatus === 1 ? 0 : 1;
    const formBody = new URLSearchParams();
    formBody.append('device_id', deviceId);
    formBody.append('value', newStatus);

    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/broadcast`, {
        method: 'POST',
        body: formBody.toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      
      if (response.ok) {
        setBroadcastStatus(newStatus);
      } else {
        console.error('Failed to update notification status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-5 w-full dark:bg-navy-800 dark:text-white rounded-xl shadow dark:border-navy-700">
      <div className="text-xl font-bold">Broadcast</div>
      <div className="text-l text-gray-500">
      Jika aktif maka fungsi broadcast akan diaktifkan pada AC ini, jika nonaktif maka fungsi broadcast akan di nonaktifkan pada AC ini, fungsi ini digunakan untuk AC yang sedang bermasalah atau sedang di perbaiki
      </div>
      <Toggle checked={broadcastStatus} onChange={handleChange} />
    </div>
  );
};

Setting.propTypes = {
  broadcastStatus: PropTypes.number.isRequired,
  setBroadcastStatus: PropTypes.func.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default Setting;
