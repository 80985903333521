  import React, { useState, useEffect, useCallback } from 'react';
  import { fetchWithJwt } from "components/fetchWithJwt";
  import { PhotoProvider, PhotoView } from 'react-photo-view';
  import { useNavigate } from 'react-router-dom';
  import { MdTimer } from 'react-icons/md';
  import Modal from 'react-modal';
  import 'react-photo-view/dist/react-photo-view.css';
  import { renderColor, renderStatusOnline } from 'helpers/statusHelpers';
  import logo from 'assets/img/logo.jpg';

  const Store = ({ selectedId, setSelectedId}) => {
    const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
    const [storeData, setStoreData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [layoutData, setLayoutData] = useState([]);
    const [nameStore, setNameStore ] = useState('');
    const [allAcData, setAllAcData] = useState(0);
    const [abnormalData, setAbnormalData] = useState(0);
    const [offlineData, setOfflineData] = useState(0);
    const [layout, setLayout] = useState('home');

    Modal.setAppElement('#root'); // Aksesibilitas untuk modal

    const openModal = (layout, store) => {
      setLayoutData(layout);
      setNameStore(store);
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
      setIsModalOpen(false);
      setLayoutData([]);
      document.body.style.overflow = 'auto';
    };

    const fetchData = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/device/show`);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
        }
        const { data } = await response.json();
        if (!data) {
          throw new Error("No data found in response");
        }
        setStoreData(data);
        if (!searchQuery) {
          setFilteredData(data);
        }
    
      } catch (error) {
        console.error("Error fetching store data:", error.message);
      }
    };
    

    const searchHandle = (value) => {
      setSearchQuery(value);
      const filtered = storeData.filter((sensor) =>
        sensor.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    };

    const goToDevice = (deviceId) => {
      setSelectedId(deviceId);
      setLayout('detail');
    };

    const formatTime = (jam_buka, jam_tutup) => {
      if (jam_buka && jam_tutup) {
        return `${jam_buka.split(":").slice(0, 2).join(":")} - ${jam_tutup.split(":").slice(0, 2).join(":")}`;
      } else {
        return "24 Jam";
      }
    };

    useEffect(() => {
      fetchData();
      const interval = setInterval(fetchData, 60000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      searchHandle(searchQuery);
    }, [searchQuery, storeData]);

    const countAbnormalData = () => {
      let count = 0;
      storeData.forEach((store) => {
          store.alat.forEach((alat) => {
              const vaStatus = alat.status && alat.status.find((stat) => stat.code === 'va_temperature');
              const online = alat.online;
              if (vaStatus && (vaStatus.value < 230 || vaStatus.value > 260) && online === 1) {
                  count += 1;
              }
          });
      });
      setAbnormalData(count);
    };

    const hitungOfflineData = () => {
      let count = 0;
      storeData.forEach((store) => {
        store.alat.forEach((alat) => {
          const sensorStatus = alat.online;
          const remoteStatus = alat.remote_online;
          if (sensorStatus === 0) {
            count += 1;
          } else if (remoteStatus === 0) {
            count += 1;
          }
        });
      });
      setOfflineData(count);
    };

    const hitungSemuaAC = () => {
      let count = 0;
      storeData.forEach((store) => {
        store.alat.forEach((alat) => {
          count += 1;
        });
      });
      setAllAcData(count);
    };

    useEffect(() => {
      countAbnormalData();
      hitungOfflineData();
      hitungSemuaAC();
    }, [storeData]);

    return (
      <div className="h-screen overflow-hidden">
        <div className="flex flex-col gap-4 shadow p-4">
          <div className='flex gap-4 items-center justify-center md:justify-start'>
            <img src={logo} alt="Logo" className="w-10" />
            <div className="text-2xl font-bold">Air System Expert</div>
          </div>
          <input
            value={searchQuery}
            onChange={(e) => searchHandle(e.target.value)}
            type="text"
            placeholder="Cari..."
            className="w-full px-4 py-2 rounded-full bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="p-4 w-full flex flex-col gap-4 overflow-scroll scrollbar-none h-full pb-[300px]">
          <div className='flex flex-col gap-4'>
            <div className="w-full bg-gradient-to-r rounded-xl from-cyan-500 to-blue-500 shadow p-4">
              <div>Jumlah Store</div>
              <div><span className='font-bold text-4xl'>{storeData.length}</span> Store</div>
            </div>
            <div className="w-full bg-gradient-to-r rounded-xl from-cyan-500 to-blue-500 shadow p-4">
              <div>Jumlah AC Terpasang</div>
              <div><span className='font-bold text-4xl'>{allAcData}</span> Unit</div>
            </div>
            <div className="w-full bg-gradient-to-r rounded-xl from-cyan-500 to-blue-500 shadow p-4">
              <div>Suhu Abnormal</div>
              <div><span className='font-bold text-4xl'>{abnormalData}</span> AC</div>
            </div>
            <div className="w-full bg-gradient-to-r rounded-xl from-cyan-500 to-blue-500 shadow p-4">
              <div>Perangkat Offline</div>
              <div><span className='font-bold text-4xl'>{offlineData}</span> Perangkat</div>
            </div>
          </div>
          {filteredData.map((store) => (
            <div key={store.id} className="flex flex-col border border-gray-300 shadow-md rounded-xl gap-2 w-full p-2">
              <div className="flex flex-col gap-3 text-center md:text-left">
                <div className="text-xl font-bold text-blue-500">{store.name}</div>
                <div className="flex flex-row gap-1 text-sm items-center justify-center md:justify-start">
                  <MdTimer />
                  <div>{formatTime(store.jam_buka, store.jam_tutup)}</div>
                </div>
                <div>
                  <button
                    onClick={() => openModal(store.data_layout, store.name)}
                    className="text-blue-500 hover:text-blue-600"
                  >
                    Layout
                  </button>
                </div>
              </div>
              <div className="w-full overflow-x-auto text-sm rounded-xl">
                <table className="w-full border">
                  <thead className='text-center'>
                      <tr>
                        <th rowSpan={2} className=''>Nomor AC</th>
                        <th rowSpan={2} className=''>Kelembaban</th>
                        <th rowSpan={2} className=''>Suhu</th>
                        <th colSpan={3} className='text-center hidden md:table-cell'>Broadcast</th>
                      </tr>
                      <tr>
                        <th className=''>Suhu</th>
                        <th className=' hidden md:table-cell'>Fan Speed</th>
                        <th className=' hidden md:table-cell'>Mode</th>
                      </tr>
                  </thead>
                  <tbody>
                    {store.alat.map((alat) => (
                      <tr
                        key={alat.device_id}
                        onClick={() => goToDevice(alat.device_id)}
                        className="hover:bg-gray-200 cursor-pointer h-12 text-center"
                      >
                        <td>AC{alat.name.slice(5, 7)}</td>
                        <td>{alat.status && alat.status.find((stat) => stat.code === 'va_humidity').value}%</td>
                        <td
                          className={
                            alat.online
                              ? renderColor(alat.status && alat.status.find((stat) => stat.code === 'va_temperature').value)
                              : 'bg-gray-400'
                          }
                        >
                          {alat.status && alat.status.find((stat) => stat.code === 'va_temperature').value / 10}
                        </td>
                        <td className={`${renderStatusOnline(alat.remote_online)}`}>{store.data_broadcast?.value || "-"}</td>
                        <td className={`hidden md:table-cell ${renderStatusOnline(alat.remote_online)}`}>
                          Middle
                        </td>
                        <td className={`hidden md:table-cell ${renderStatusOnline(alat.remote_online)}`}>
                          Cold
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>

        {/* Modal Component */}
        {isModalOpen && (
          <PhotoProvider>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded p-4 max-w-2xl w-full overflow-y-auto m-4">
                <h2 className="text-lg font-bold mb-4">{nameStore}</h2>
                <p className="text-sm mb-4 text-black-600">Klik pada gambar untuk memperbesar gambar</p>
                <div className='flex flex-wrap gap-2'>
                  {layoutData.map((layout, index) => (
                    <div>
                    <PhotoView key={index} src={`${API_DOMAIN}/img/layout/${layout.nama_file}`}>
                      <img
                        src={`${API_DOMAIN}/img/layout/${layout.nama_file}`}
                        alt={layout.nama_file}
                        className="h-20 w-20 hover:scale-110 cursor-pointer shadow-md rounded-lg"
                      />
                    </PhotoView>
                    <p className='text-sm text-center'>{index + 1}</p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-red-500 hover:bg-opacity-80 text-white rounded mt-4"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </PhotoProvider>
        )}
      </div>
    );
  };

  export default Store;
