import React, { useState, useEffect } from "react";
import Temperature from "./temperature";
import PowerOff from "./powerOff";

const Broadcast = () => {
  const [layout , setLayout] = useState("temperature");

  return (
    <>
      <ul className="flex px-4 flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li className="mr-2">
          <button
            onClick={() => setLayout("temperature")}
            className={`inline-block p-4 ${
              layout === "temperature"
                ? "text-cyan-600 bg-white"
                : "hover:text-gray-600 hover:bg-gray-50"
            }`}
          >
            Temperatur
          </button>
        </li>
        <li className="mr-2">
          <button
            onClick={() => setLayout("powerOff")}
            className={`inline-block p-4 ${
              layout === "powerOff"
                ? "text-cyan-600 bg-white"
                : "hover:text-gray-600 hover:bg-gray-50"
            }`}
          >
            Power Off
          </button>
        </li>
      </ul>
      {layout === "temperature" && <Temperature layout={layout} setLayout={setLayout} />}
      {layout === "powerOff" && <PowerOff layout={layout} setLayout={setLayout} />}
    </>
   
  );
};

export default Broadcast;
